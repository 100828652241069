import React ,{ useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import {
  FaClock,
  FaEnvelope,
  FaFacebookF,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaTwitter,
} from "react-icons/fa";
import ItechExsLogo from '../../assests/images/ItechExsLogo.png';
import './Header.css'

function Header() {

  const [className, setClassName] = useState('');
  // const [className, setClassName] = useState('');
  // const [className, setClassName] = useState('');

  useEffect(() => {
      const handleScroll = () => {
          if (window.innerWidth < 992) {
              document.querySelector(".navbar-nav").style.display="none"
              if (window.scrollY > 45) {
                  setClassName('glasseffect shadow ');
              } else {
                  setClassName('');
              }
          } else {
              if (window.scrollY > 45) {
                  setClassName('glasseffect shadow');
                  document.querySelector('.fixed-top').style.top = '0';
                  document.querySelector(".navbar-nav").style.display="flex"
                  
                  
              } else {
                  setClassName('');
                  document.querySelector('.fixed-top').style.top = '0';
                   document.querySelector(".navbar-nav").style.display="none"
                 
              }
          }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  return (
    <div>
      {" "}
      <div
        className={`container-fluid fixed-top navbar-section px-0 wow fadeIn ${className} `}
        data-wow-delay="0.1s"
      >

        <nav
          className="navbar navbar-expand-lg navbar-light py-lg-0 px-lg-5 wow fadeIn justify-content-center"
          data-wow-delay="0.1s"
        >
          <Link to="/" className="navbar-brand ms-4 ms-lg-0 ">
          <img
            src={ItechExsLogo}
            className="logo my-2"
            alt="Logo"
          />
          </Link>
          {/* <button
            type="button"
            className="navbar-toggler me-4"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          {/* <div className="collapse navbar-collapse " id="navbarCollapse"> */}
            <div className="navbar-nav ms-auto p-4 p-lg-0  " style={{display:'none'}}>
              <Link to="/" className="nav-item nav-link active">
                Home
              </Link>
              <a href="#about" class="nav-item nav-link">
                About
              </a>
              <a href="#services" class="nav-item nav-link">
                Services
              </a>

              {/* <Link to="/contact-us" className="nav-item nav-link">
                Contact
              </Link> */}
            {/* </div> */}
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header