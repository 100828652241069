import logo from './logo.svg';
import "../src/assests/css/style.css";
import "../src/assests/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from './Components/Home/HomePage';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
      <Routes>
        <Route element={<HomePage />} path=""/>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
